import { AppBar, Toolbar } from '@material-ui/core';
import React from 'react';
import useRegions from '../../hooks/useRegions';
import CustomerLogo from './CustomerLogo/CustomerLogo';
import HeaderImage from './HeaderImage/HeaderImage';
import LanguageSelect from './LanguageSelect/LanguageSelect';
import RegionSelect from './RegionSelect/RegionSelect';
import useStyles from './styles';
import UserMenu from './UserMenu/UserMenu';

interface HeaderProps {
  logout: () => void;
}

const Header: React.FC<HeaderProps> = ({ logout }) => {
  const { regions } = useRegions();
  const classes = useStyles();

  return (
    <>
      <AppBar position="sticky" className={classes.root} data-testid="Header">
        <Toolbar className={classes.toolbar}>
          <CustomerLogo />
          <div className={classes.toolbarRight}>
            <LanguageSelect />
            <UserMenu logout={logout} />
          </div>
        </Toolbar>
      </AppBar>
      <HeaderImage />
      {regions && <RegionSelect regions={regions} />}
    </>
  );
};

export default Header;
