import { UserInfo } from '../types/UserInfo';
import StartUrlParameters from './startParameters';

export const USER_INFO_LS_KEY = 'user_info';
export const EXPIRES_AT_LS_KEY = 'expires_at';
export const AUTH_STATE_LS_KEY = 'authState';
export const ACCESS_TOKEN_LS_KEY = 'access_token';
export const ORGANISATION_LS_KEY = 'organisation';
export const REDIRECT_KEY = 'redirect';

export const isAuthenticated = (): boolean => {
  const expiresAt = localStorage.getItem(EXPIRES_AT_LS_KEY);
  return expiresAt ? new Date().getTime() < JSON.parse(expiresAt) : false;
};

export const getUserInfo = (): UserInfo | null => {
  const lsUserInfo = localStorage.getItem(USER_INFO_LS_KEY);
  return lsUserInfo ? JSON.parse(lsUserInfo) : null;
};

export const getAccessToken = () => {
  return isAuthenticated() ? localStorage.getItem(ACCESS_TOKEN_LS_KEY) : '';
};

export const getLang = () => {
  return localStorage.getItem(StartUrlParameters.lang);
};

export const getOrganisation = () => {
  const lsOrg = localStorage.getItem(ORGANISATION_LS_KEY);
  if (lsOrg && lsOrg !== 'undefined') return lsOrg;

  const paths = window.location.pathname.split('/');
  if (paths.length > 1) {
    const organisationPath = paths[1];
    const excludedPaths = ['logout', 'callback'];
    if (organisationPath.length && !excludedPaths.includes(organisationPath))
      return organisationPath;
  }

  return global.env.REACT_APP_ORGANIZATION;
};

export const setLSOrganisation = (organisation: string) => {
  return localStorage.setItem(ORGANISATION_LS_KEY, organisation);
};

export const clearAuthData = () => {
  localStorage.removeItem(ACCESS_TOKEN_LS_KEY);
  localStorage.removeItem(EXPIRES_AT_LS_KEY);
  localStorage.removeItem(USER_INFO_LS_KEY);
};

export const getHighest = (...numbers: Array<number | undefined>): number => {
  return (
    numbers.reduce((prev, cur) => {
      return Math.max(Number(prev || 0), Number(cur || 0));
    }) || 0
  );
};
