import { CircularProgress, Container } from '@material-ui/core';
import React from 'react';
import useGetProjectsQuery from '../../axios/hooks/useGetProjectsQuery';
import useStyles from './styles';

const SpinnerFullscreen: React.FC = () => {
  const classes = useStyles();
  const projectsQuery = useGetProjectsQuery();

  return (
    <Container maxWidth={false} className={classes.spinnerFullScreen}>
      {projectsQuery.data?.organisationLogoDarkThumb && (
        <img
          className={classes.logo}
          src={projectsQuery.data?.organisationLogoDarkThumb}
          alt={projectsQuery.data?.organisationName}
        />
      )}
      <CircularProgress />

      {/* This is needed for auth0 lock initialization */}
      <div style={{ display: 'none' }} id="login-container" />
    </Container>
  );
};

export default SpinnerFullscreen;
