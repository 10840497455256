import { Typography, Button, Grid, Box } from '@material-ui/core';
import { ArrowRightAlt } from '@material-ui/icons';
import React from 'react';
import useStyles from './styles';

export interface Sectionable {
  name: string;
  description?: string;
  width?: React.CSSProperties['width'];
  action?: () => void;
  to?: string;
}

export interface ActionButton {
  buttonText: string;
  url: string;
}

interface SectionCardProps {
  component: Sectionable;
  actions: ActionButton[];
  children?: React.ReactNode;
  width?: number | string;
  to?: string;
}

const openLink = (url: string) => {
  window.open(url, '_blank');
};

export default function SectionCard({ component, children, actions, width }: SectionCardProps) {
  const { name, description } = component;
  const styleWidth = width || '33.33%';
  const classes = useStyles({ width: styleWidth });

  return (
    <Box className={classes.root} data-testid="SectionCard">
      <Grid
        container
        className={classes.gridContainer}
        direction="column"
        spacing={1}
        justifyContent="space-between"
      >
        <Grid item container justifyContent="center">
          <Grid item container direction="column" className={classes.sectionHeader}>
            <Grid item>
              <Typography className={classes.title}>{name}</Typography>
            </Grid>
            {description && (
              <Grid item>
                <Typography className={classes.subheader}>{description}</Typography>
              </Grid>
            )}
          </Grid>
          <Grid item className={classes.content}>
            {children}
          </Grid>
          <Grid item container direction="row" className={classes.actionArea}>
            {actions.map((action, i) => (
              <Button
                data-testid="action-button"
                key={action.buttonText + i}
                onClick={() => openLink(action.url)}
                size="small"
              >
                <Typography className={classes.actionButtonText}>{action.buttonText}</Typography>
                <ArrowRightAlt className={classes.icon} />
              </Button>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
