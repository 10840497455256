import { StartUrlParameters } from '../utils/startParameters';
import { getRedirectUrl } from '../utils/redirections';
import { useEffect, useState } from 'react';
import useProjects from './useProjects';
import useStartParams from './useStartParams';
import { UserLevel } from '../types/UserInfo';
import { getHighest } from '../utils';
import { Project } from '../types/Project';

const useRedirect = () => {
  const { projects, environments, loading } = useProjects();
  const startParams = useStartParams();
  const [redirecting, setRedirecting] = useState<boolean>(loading);

  const selectUserProjects = (userProjects: Project[]): Project[] => {
    const externalProjectId = startParams[StartUrlParameters.projectId];

    if (!externalProjectId) {
      if (environments.length > 1) return [];

      return projects;
    }

    const project = userProjects.find(
      (p: Project) => String(p.externalId) === String(externalProjectId)
    );

    return project ? [project] : [];
  };

  window.addEventListener('onunload', () => {
    // redirection done
    setRedirecting(false);
  });

  useEffect(() => {
    setRedirecting(loading);

    if (
      loading ||
      !projects ||
      !projects.length ||
      startParams[StartUrlParameters.redirect] === 'false'
    )
      return;

    const selectedProjects: Project[] = selectUserProjects(projects) || ([] as Project[]);

    if (!selectedProjects.length) return;

    const level = getHighest(
      selectedProjects[0].environmentLevel,
      selectedProjects[0].organisationLevel,
      selectedProjects[0].level,
      selectedProjects[0].apartments?.[0]?.level
    );

    if (level === UserLevel.SUPER_USER) return;

    setRedirecting(true);

    const url = getRedirectUrl({
      project: selectedProjects[0],
      level,
      redirectTo: selectedProjects.length > 1 ? 'cj1' : startParams[StartUrlParameters.redirectTo]
    });

    if (!url) {
      setRedirecting(false);
      return;
    }

    window.location.replace(url);
  }, [loading]);

  return redirecting;
};

export default useRedirect;
