import { RelatedSettings } from './../types/Project';
import { Environment } from './../axios/instances/types';
import { useParams } from 'react-router-dom';
import useGetProjectsQuery from '../axios/hooks/useGetProjectsQuery';
import { APIProject } from '../axios/instances/types';
import { t } from '../locales/utils';
import { Project } from '../types/Project';
import { UserInfoProject } from '../types/UserInfo';
import { getUserInfo, isAuthenticated } from '../utils';

const getRelatedEnvSettings = (env: Environment): Omit<RelatedSettings, 'organisationId'> => ({
  useCJ3: env.settings.cj3IsActive,
  useCJ2: env.settings.customerJourney2_isActive,
  cj3Url: env.settings.cj3Url,
  ccUrl: env.settings.coreClientUrl,
  cj2Url: env.settings.customerJourney2_url,
  coreUrl: env.settings.environmentUrl,
  tenantId: env.id
});

type Regions = Record<
  string,
  Array<
    APIProject & {
      environmentLevel?: number;
      organisationLevel?: number;
      settings: RelatedSettings;
    }
  >
>;

export type UserRegions = Record<string, Array<Project>>;

export interface UserRegionsResponse {
  regions: UserRegions;
  environments: Environment[];
  loading: boolean;
}

const useRegions = (): UserRegionsResponse => {
  const { organisationId } = useParams();

  const userInfo = getUserInfo();

  if (!userInfo || !isAuthenticated()) return { regions: {}, environments: [], loading: false };

  const projectsQuery = useGetProjectsQuery();

  const organisation = userInfo['https://gbuilder.com/a_md']?.organisations?.find(
    (org) => org.id === organisationId
  );

  const userEnvironmentsWithProjects =
    projectsQuery.data?.environments.filter((e) => {
      const userEnv = organisation?.environments?.find((ue) => ue.schemaName === e.schema);

      return e.projects && e.projects.length > 0 && userEnv;
    }) || [];

  const allUserProjects = organisation?.environments?.reduce((projects, environment) => {
    projects.push(...(environment.projects || []));
    return projects;
  }, [] as UserInfoProject[]);
  const userEnvironments = organisation?.environments || [];
  const organisationLevel = organisation?.level;

  const regions: Regions =
    userEnvironmentsWithProjects.reduce((regions, environment) => {
      const settings: RelatedSettings = {
        ...getRelatedEnvSettings(environment),
        organisationId: organisation?.id || ''
      };

      for (const project of environment.projects) {
        const region = project.region || t('other');
        const environmentLevel = userEnvironments.find(
          (env) => env.schemaName === environment.schema
        )?.level;

        if (regions[region]) {
          regions[region].push({ ...project, environmentLevel, organisationLevel, settings });
        } else {
          regions[region] = [{ ...project, environmentLevel, organisationLevel, settings }];
        }
      }

      return regions;
    }, {} as Regions) || {};

  const userRegions: UserRegions =
    regions && allUserProjects
      ? Object.entries(regions).reduce((userRegions, [region, projects]) => {
          for (const project of projects) {
            const userProject = allUserProjects.find(
              (userProject) => userProject.id === project.id
            );

            if (userProject || project.environmentLevel || project.organisationLevel) {
              if (userRegions[region]) {
                userRegions[region].push({ ...project, ...userProject });
              } else {
                userRegions[region] = [{ ...project, ...userProject }];
              }
            }
          }

          return userRegions;
        }, {} as UserRegions)
      : {};

  return {
    regions: userRegions,
    environments: userEnvironmentsWithProjects,
    loading: projectsQuery.isFetching
  };
};

export default useRegions;
