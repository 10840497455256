export enum StartUrlParameters {
  lang = 'i18nextLng',
  error = 'error',
  returnTo = 'returnTo',
  connection = 'connection',
  redirect = 'redirect',
  redirectTo = 'redirectTo',
  projectId = 'projectId',
  apartmentId = 'apartmentId',
  logout = 'logout',
  debug = 'debug'
}

export const StartUrlParametersArray: string[] = [
  StartUrlParameters.lang,
  StartUrlParameters.error,
  StartUrlParameters.returnTo,
  StartUrlParameters.connection,
  StartUrlParameters.redirect,
  StartUrlParameters.redirectTo,
  StartUrlParameters.projectId,
  StartUrlParameters.apartmentId,
  StartUrlParameters.logout,
  StartUrlParameters.debug
];

const unstoredParameters = [
  StartUrlParameters.debug,
  StartUrlParameters.connection,
  StartUrlParameters.error
];

export const getStartParameters = () => {
  const urlParams = Object.fromEntries(new URLSearchParams(window.location.search.substring(1)));

  if (urlParams[StartUrlParameters.lang]) {
    window.localStorage.setItem('i18nextLng', urlParams[StartUrlParameters.lang]);
    delete urlParams[StartUrlParameters.lang];
  }

  const isDebug = !!urlParams[StartUrlParameters.debug];

  const newParams = !!Object.keys(urlParams).length && !isDebug;

  const localStorageParams: { [x: string]: string } | null = newParams
    ? null
    : StartUrlParametersArray.reduce((lsParams, cur) => {
        const param = window.localStorage.getItem(cur);
        if (param) lsParams[cur] = param;
        return lsParams;
      }, {} as { [x: string]: string });

  if (newParams && !isDebug) {
    StartUrlParametersArray.forEach((param) => window.localStorage.removeItem(param));
  }

  for (const [key, value] of Object.entries(urlParams)) {
    if (!StartUrlParametersArray.includes(key)) {
      delete urlParams[key];
    } else if (!unstoredParameters.includes(key as StartUrlParameters))
      window.localStorage.setItem(key, value);
  }

  const startParams = { ...localStorageParams, ...urlParams };
  let url = window.location.pathname;

  if (isDebug || startParams[StartUrlParameters.connection])
    url += `?${new URLSearchParams(startParams).toString()}`;

  window.history.replaceState(null, '', url);

  return startParams;
};

export const getLsParams = () => {
  return StartUrlParametersArray.reduce((lsParams, cur) => {
    const param = window.localStorage.getItem(cur);
    if (param) lsParams[cur] = param;
    return lsParams;
  }, {} as { [x: string]: string });
};

export default StartUrlParameters;
