export interface UserInfoApartment {
  id: number
  level: number
}

export interface UserInfoProject {
  apartments?: UserInfoApartment[]
  id?: number
  level?: number
  environmentLevel?: number
  organisationLevel?: number
}

export interface UserInfoEnv {
  projects?: UserInfoProject[]
  schemaName: string
  level?: number
}

export interface UserInfoOrganisation {
  id: string
  environments: UserInfoEnv[]
  level?: number
}

export interface UserInfo {
  sub: string
  nickname: string
  name: string
  picture: string
  updated_at: string
  email: string
  email_verified: boolean
  "https://gbuilder.com/a_md": {
    organisations: UserInfoOrganisation[]
  }
}

export enum UserLevel {
  PUBLIC = -1,
  VIRTUAL_BUYER = 0,
  VISITOR = 1,
  REAL_ESTATE_AGENT = 2,
  BASIC_OWNER = 3,
  ADVANCED_OWNER = 4,
  BUILDER = 5,
  MAINTENANCE = 6,
  INSPECTOR = 7,
  ARCHITECT = 8,
  WORKSITE_MANAGER = 9,
  PROJECT_MANAGER = 10,
  ADMINISTRATOR = 11,
  SUPER_USER = 12
}
