
import ImagePresenter from "../ImagePresenter/ImagePresenter"
import { Attachable, AttachmentFileType } from "./types"

const getInnerContent = ({
  source,
  title,
  mimetype,
  type,
}: Partial<Attachable>) => {
  const FallbackEmbed = (
    <embed
      data-testid="ModalEmbed-Fallback"
      src={source}
      style={{ height: "100%", width: "100%", objectFit: "contain" }}
    />
  )

  switch (type) {
  case AttachmentFileType.IMAGE:
    return (
      <ImagePresenter
        data-testid="ModalEmbed-ImagePresenter"
        src={source || ""}
        alt={title || "image-file"}
        style={{
          position: "relative",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          border: 0,
        }}
      />
    )
  case AttachmentFileType.VIDEO:
    return (
      <video controls data-testid="ModalEmbed-Video">
        <source src={source} type={mimetype} />
        {FallbackEmbed}
      </video>
    )
  default:
    return FallbackEmbed
  }
}

const ModalEmbed = (props: Partial<Attachable>) => {
  const { source, height, width, title, mimetype, type } = props
  return getInnerContent({
    source,
    height,
    width,
    title,
    mimetype,
    type,
  })
}

export default ModalEmbed
