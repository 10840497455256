import { useEffect, useState } from 'react';
import useGetProjectsQuery from '../axios/hooks/useGetProjectsQuery';
import { ORGANISATION_LS_KEY } from '../utils';

const DEFAULT_FONT_FAMILY = 'Helvetica Neue';

const useCustomFont = () => {
  const projectsQuery = useGetProjectsQuery();
  const organisation = localStorage.getItem(ORGANISATION_LS_KEY);
  const [fontFamily, setFontFamily] = useState(DEFAULT_FONT_FAMILY);
  const [fontUrl, setFontUrl] = useState<string>();

  useEffect(() => {
    if (projectsQuery.isLoading) return;

    const customization = projectsQuery.data?.organizationCustomization;

    if (customization && customization.fontName) {
      setFontFamily(String(organisation));
      setFontUrl(`${global.env.REACT_APP_FONTCDN_URL}/${organisation}/${organisation}.css`);
    }
  }, [projectsQuery.isLoading]);

  return { fontFamily: [fontFamily, 'sans-serif'].join(','), fontUrl };
};

export default useCustomFont;
