import {
  ACCESS_TOKEN_LS_KEY,
  clearAuthData,
  getOrganisation,
  ORGANISATION_LS_KEY
} from './../../utils/index';
import { getProjects } from '../instances/app';
import { useQuery, UseQueryResult } from 'react-query';
import { GetProjectsParams, ProjectsResponse } from '../instances/types';
import { useParams } from 'react-router-dom';

export type ProjectsQueryType = UseQueryResult<ProjectsResponse | undefined, unknown>;

export const PROJECTS_QUERY_KEY = 'projects';

const useGetProjectsQuery = (): ProjectsQueryType => {
  const { organisationId } = useParams();
  const orgId = organisationId || getOrganisation();
  const paths = window.location.pathname.split('/');

  const onError = () => {
    clearAuthData();
    window.localStorage?.removeItem(ACCESS_TOKEN_LS_KEY);
    window.localStorage?.removeItem(ORGANISATION_LS_KEY);

    window.location.replace(`/${getOrganisation()}/error`);
  };

  const projectsQuery = useQuery<
    ProjectsResponse,
    unknown,
    ProjectsResponse,
    [string, GetProjectsParams]
  >(
    [
      PROJECTS_QUERY_KEY,
      {
        organisationId: orgId
      }
    ],
    getProjects,
    {
      retry: false,
      staleTime: 60 * 60 * 1000,
      enabled: !paths.includes('error'),
      onError: (error) => {
        console.log(`Error while getting projects for ${orgId}`, error);
        onError();
      },
      onSuccess: (data) => {
        if (!data.environments || !data.environments.length) onError();
      }
    }
  );

  return projectsQuery;
};

export default useGetProjectsQuery;
