import { createStyles, makeStyles } from "@material-ui/core/styles"

export default makeStyles(() =>
  createStyles({
    sections: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      position: "relative",
      zIndex: 1,
    },
  })
)
