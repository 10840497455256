import { Container } from '@material-ui/core';
import React from 'react';
import { useEffect } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Sections from '../../components/Sections/Sections';
import useRedirect from '../../hooks/useRedirect';
import { getOrganisation, isAuthenticated, setLSOrganisation } from '../../utils';
import Login from '../Login/Login';
import SpinnerFullscreen from '../../components/SpinnerFullscreen/SpinnerFullscreen';
import useStyles from './styles';
import useStartParams from '../../hooks/useStartParams';
import StartUrlParameters from '../../utils/startParameters';
import useProjects from '../../hooks/useProjects';

const MainContent: React.FC<{ logout: () => void }> = ({ logout }) => {
  const classes = useStyles();
  const redirecting = useRedirect();
  const startParams = useStartParams();
  const { projects, loading } = useProjects();

  if (startParams[StartUrlParameters.logout]) {
    logout();
    return null;
  }

  if (redirecting || startParams[StartUrlParameters.connection]) return <SpinnerFullscreen />;
  else if (!loading && !projects.length) {
    window.localStorage?.setItem(StartUrlParameters.error, 'error.projectsFetchFailed');
    logout();
    return null;
  }

  return (
    <>
      <Header logout={logout} />
      <Container data-testid="MainContainer" className={classes.mainContentWrapper}>
        <Sections />
      </Container>
      <Footer />
      {/* This is needed for auth0 lock initialization */}
      <div style={{ display: 'none' }} id="login-container" />
    </>
  );
};

const Main: React.FC<{ login: null | (() => void); logout: () => void }> = ({ login, logout }) => {
  const { organisationId } = useParams();
  const startParams = useStartParams();

  useEffect(() => {
    setLSOrganisation(organisationId || getOrganisation());
  }, [organisationId]);

  return !isAuthenticated() ? (
    <Routes>
      <Route path="login" element={<Login login={login} />} />
      <Route
        path="*"
        element={
          <Navigate
            replace
            to={`/${organisationId}/login?${new URLSearchParams(startParams).toString()}`}
          />
        }
      />
    </Routes>
  ) : (
    <Routes>
      <Route path="login" element={<Navigate replace to={`/${organisationId}`} />} />
      {['', ':region'].map((path, index) => {
        return <Route key={index} path={path} element={<MainContent logout={logout} />} />;
      })}
    </Routes>
  );
};

export default Main;
