import { createStyles, makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      gridColumn: "3",
      justifySelf: "end"
    },
  })
})

export default useStyles
