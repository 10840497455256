import en from "./en/main.json"
import fi from "./fi/main.json"
import cs from "./cs/main.json"
import de from "./de/main.json"
import es from "./es/main.json"
import et from "./et/main.json"
import fr from "./fr/main.json"
import lt from "./lt/main.json"
import nb from "./nb/main.json"
import nl from "./nl/main.json"
import ru from "./ru/main.json"
import sk from "./sk/main.json"
import sv from "./sv/main.json"

export default { en, fi, cs, de, es, et, fr, lt, nb, nl, ru, sk, sv }
