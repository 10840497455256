import i18next, { TOptions } from 'i18next';

const useText = () => {
  return (translationString: string, options?: TOptions) => {
    const translated = i18next.t([`${translationString}`, `main.${translationString}`], options);

    return translated === '-' ? '' : translated;
  };
};

export default useText;
