import { GetProjectsParams, ProjectsResponse } from './types';
import axios from 'axios';

import { createConfig } from './utils';
import { QueryFunction } from 'react-query';

export const instance = axios.create(createConfig({}));

export const getProjects: QueryFunction<ProjectsResponse, [string, GetProjectsParams]> = async (
  params
) => {
  const { organisationId } = params.queryKey[1];

  const response = await instance.get<{ data: ProjectsResponse }>(
    `/v1/public/organisations/${organisationId}/projects`
  );

  return response?.data?.data;
};
