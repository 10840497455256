import { Alert } from '@material-ui/lab';
import React from 'react';
import { useParams } from 'react-router-dom';
import useProjects from '../../hooks/useProjects';
import useRegions from '../../hooks/useRegions';
import useText from '../../hooks/useText';
import Project from './Project/Project';
import useStyles from './styles';

const Sections: React.FC = () => {
  const classes = useStyles();
  const { regions } = useRegions();
  const { projects } = useProjects();
  const { region } = useParams();
  const text = useText();

  if (!projects.length) {
    return (
      <Alert severity="info" variant="outlined">
        {text('noUserProjects')}
      </Alert>
    );
  }

  const currentRegion =
    regions &&
    Object.entries(regions).find(([reg]) => {
      return reg.toLowerCase() === region;
    });

  return (
    <div className={classes.sections} data-testid="Sections">
      {currentRegion?.[1]?.map((project, i) => (
        <Project key={project.id + i} project={project} />
      )) || projects.map((project, j) => <Project key={project.id + j} project={project} />)}
    </div>
  );
};

export default Sections;
