import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    image: {
      width: '100vw',
      height: '100%',
      objectFit: 'cover'
    },
    imageContainer: {
      verticalAlign: 'middle',
      textAlign: 'center',
      backgroundPosition: 'center',
      overflow: 'hidden',
      position: 'relative',
      width: '100%',
      height: '24rem',
      marginBottom: '-35rem'
    },
    layer: {
      position: 'relative',
      zIndex: 2,
      bottom: 0,
      left: 0,
      width: '100%',
      height: '35rem',
      marginBottom: '-3rem',
      backgroundColor: 'rgba(0,0,0,0.4)'
    },
    header: {
      color: theme.palette.common.white,
      textShadow: '0 0 0.5rem rgb(0, 0, 0, .5)',
      ['&&']: {
        // force the style, because it gets overridden for some reason.
        marginBottom: '1rem'
      },
      [theme.breakpoints.down('md')]: {
        textAlign: 'center'
      }
    },
    subheader: {
      color: theme.palette.common.white,
      textShadow: '0 0 0.5rem rgb(0, 0, 0, .5)',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center'
      }
    },
    headerContainer: {
      paddingLeft: '10rem',
      paddingRight: '10rem',
      position: 'relative',
      zIndex: 3,
      top: -150,
      width: '100%',
      height: '3rem',
      [theme.breakpoints.down('lg')]: {
        top: -200
      },
      [theme.breakpoints.down('md')]: {
        paddingLeft: '2rem',
        paddingRight: '2rem'
      },
      [theme.breakpoints.down('sm')]: {
        top: -200
      },
      '@media screen and (max-width: 480px)': {
        paddingLeft: '.5rem',
        paddingRight: '.5rem'
      }
    }
  })
);
