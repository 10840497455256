import {
  createStyles,
  makeStyles,
  Theme
} from "@material-ui/core/styles"

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.common.white,
      boxShadow: "none",
  
    },
    toolbar: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      justifyContent: "space-between",
      gridTemplateRows: "1fr",
      margin: "0 10rem",
      padding: "0",
      [theme.breakpoints.down("md")]: {
        margin: "0 2rem",
      },
    },
    toolbarRight: {
      gridColumn: "2",
      display: "flex",
      justifyContent: "flex-end"
    }
  })
)
