import { grey } from "@material-ui/core/colors"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

export default makeStyles((theme: Theme) =>
  createStyles({
    regionButton: {
      flex: "0 1 auto",
      alignSelf: "auto",
      padding: "1.5rem 2rem 1.5rem",
      textDecoration: "none",
      textTransform: "none",
      "&:not(:first-child)": {
        marginLeft: "2rem",
      },
      backgroundColor: theme.palette.common.white,
      color:
        theme.props?.MuiButton?.variant === "outlined"
          ? theme.palette.primary.main
          : theme.palette.common.black,
      fontSize: theme.typography.h4.fontSize,
      fontWeight: theme.typography.fontWeightLight as any, // eslint-disable-line
      borderBottom:
        theme.props?.MuiButton?.variant === "text"
          ? "0.15rem solid transparent"
          : theme.props?.MuiButton?.variant === "outlined"
            ? `0.15rem solid ${theme.palette.primary.main}`
            : undefined,
      "&:hover": {
        borderBottom:
          theme.props?.MuiButton?.variant === "text"
            ? `0.15rem solid ${grey[500]}`
            : undefined,
        backgroundColor:
          theme.props?.MuiButton?.variant === "outlined"
            ? theme.palette.primary.main
            : undefined,
        color:
          theme.props?.MuiButton?.variant === "outlined"
            ? theme.palette.common.white
            : undefined,
        "& img": {
          filter:
            theme.name === "ion" || theme.name === "sonell"
              ? "brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);"
              : undefined,
        },
      },
    },

    highlight: {
      borderBottom:
        theme.props?.MuiButton?.variant === "text"
          ? `0.15rem solid ${theme.palette.primary.main}`
          : undefined,
      backgroundColor:
        theme.props?.MuiButton?.variant === "contained"
          ? theme.palette.primary.main
          : theme.props?.MuiButton?.variant === "outlined"
            ? theme.palette.primary.main
            : undefined,
      color:
        theme.props?.MuiButton?.variant === "contained"
          ? theme.palette.common.white
          : theme.props?.MuiButton?.variant === "outlined"
            ? theme.palette.common.white
            : undefined,
      "& img": {
        filter:
          theme.name === "ion" || theme.name === "sonell"
            ? "brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);"
            : undefined,
      },
      "&:hover": {
        color:
          theme.props?.MuiButton?.variant === "contained"
            ? theme.palette.common.black
            : theme.props?.MuiButton?.variant === "outlined"
              ? theme.palette.common.white
              : undefined,
      },
    },
    toolbar: {
      justifyContent: "center",
      alignItems: "stretch",
      paddingTop: theme.spacing(3)
    },
    placeholderHighlight: {
      height: "0.15rem",
      width: "100%",
    },
    phaseContainer: {
      alignContent: "center",
    },
    autocomplete: {
      width: "15rem",
      ["& .MuiInputBase-root"]: {
        height: theme.typography.pxToRem(48),
        paddingLeft: theme.typography.pxToRem(16),
        ["& input"]: {
        }
      },
      ["&.regionSelected .MuiInputBase-root"]: {
        color: theme.palette.primary.main
      },
      ["&:hover .MuiOutlinedInput-notchedOutline"]: {
        borderColor: theme.palette.primary.main
      }
    },
    listbox: {
      color: theme.palette.primary.main,
    }
  })
)
