import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

export default makeStyles((theme: Theme) =>
  createStyles({
    modalContainer: {
      position: "absolute",
      background: theme.palette.common.white,
      padding: theme.spacing(0),
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      margin: "auto",
      maxWidth: "75%",
      maxHeight: "85%",
    },
    grow: {
      flexGrow: 1,
    },
    title: {
      paddingLeft: "1rem",
      margin: "auto",
      paddingTop: "1rem",
      fontSize: theme.typography.h3.fontSize,
    },
    fileName: {
      margin: "auto",
      paddingLeft: "1rem",
      paddingBottom: "1rem",
      fontSize: theme.typography.h5.fontSize,
    },
    downloadGrid: {
      margin: "1rem auto",
    },
    downloadButton: {
      fontSize: theme.typography.subtitle1.fontSize,
    },
    paper: {
      maxWidth: "calc(100vw - 3rem)",
    },
  })
)
