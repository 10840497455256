import { FC, useState } from 'react';
import styles from './styles';

export interface ImageProps {
  small: string;
  medium: string;
  large: string;
  original: string;
}

const FALLBACK_IMAGE_SRC = 'https://gbgeneric.blob.core.windows.net/gbimages/image_(22).png';

const LandingPageImage: FC<ImageProps> = (images: ImageProps) => {
  const [imageLoadFailed, setImageLoadFailed] = useState<boolean>(false);
  const classes = styles();
  const altText = 'Login page image';
  const hasImages = images && images.original && !imageLoadFailed;

  return (
    <>
      {hasImages ? (
        <picture>
          <source media="(max-width: 1880px) and (max-height: 1080px)" srcSet={images.medium} />
          <source media="(max-width: 3960px) and (max-height: 2160px)" srcSet={images.large} />
          <img
            data-testid="landing-page-image"
            className={classes.loginImage}
            src={images.original}
            alt={altText}
            onError={() => {
              setImageLoadFailed(true);
            }}
          />
        </picture>
      ) : (
        <img className={classes.loginImage} src={FALLBACK_IMAGE_SRC} alt={altText} />
      )}
      ;
    </>
  );
};

export default LandingPageImage;
