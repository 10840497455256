import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
  image: {
    maxWidth: "100px",
    paddingRight: "1rem",
    height: "auto",
    gridColumn: "1"
  }
}))

export default useStyles
