import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

export default makeStyles((theme: Theme) =>
  createStyles({
    main: {
      top: "auto",
      bottom: 0,
      height: "3rem",
      alignSelf: "start",
      backgroundColor: theme.palette.common.white,
    },
    links: {
      paddingTop: "0.75rem",
      alignSelf: "flex-start",
    },
    link: {},
  })
)
