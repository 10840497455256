export interface LanguageType {
  code: string;
  title: string;
}

export const languages: LanguageType[] = [
  {
    code: 'en-US',
    title: 'English (US)'
  },
  {
    code: 'fi-FI',
    title: 'Finnish'
  },
  {
    code: 'nl-NL',
    title: 'Nederlands'
  },
  {
    code: 'fr-FR',
    title: 'Français'
  },
  {
    code: 'cs-CZ',
    title: 'Czech'
  },
  {
    code: 'de-DE',
    title: 'German'
  },
  {
    code: 'es_ES',
    title: 'Spanish'
  },
  {
    code: 'et_EE',
    title: 'Estonian'
  },
  {
    code: 'lt_LT',
    title: 'Lithuanian'
  },
  {
    code: 'nb_NO',
    title: 'Norwegian'
  },
  {
    code: 'ru_RU',
    title: 'Russian'
  },
  {
    code: 'sk_SK',
    title: 'Slovakian'
  },
  {
    code: 'sv_SE',
    title: 'Swedish'
  }
];
