import { Grid, Modal, Button } from "@material-ui/core"
import useStyles from "./styles"
import ModalEmbed from "./ModalEmbed"
import { AttachmentType } from "./types"
import { t } from "../../locales/utils"

interface AttachmentViewerProps {
  attachment?: AttachmentType
  handleClose: () => void
  open?: boolean
}

const AttachmentViewer: React.FC<AttachmentViewerProps> = ({attachment, open, handleClose}) => {
  const classes = useStyles()

  if (attachment) {
    const { name, link } = attachment
    const download = () => {
      window.open(link, "_self")
    }
    return (
      <Modal open={!!open} onClose={handleClose} data-testid="AttachmentViewer">
        <Grid container direction="column" className={classes.modalContainer}>
          <Grid item className={classes.title}>
            {t("attachments.attachmentViewer")}
          </Grid>
          <Grid item className={classes.fileName}>
            {name}
          </Grid>
          <Grid item className={classes.grow}>
            <ModalEmbed source={link} title={name} />
          </Grid>
          <Grid item className={classes.downloadGrid}>
            <Button onClick={download} className={classes.downloadButton}>
              {t("attachments.download")}
            </Button>
          </Grid>
        </Grid>
      </Modal>
    )
  } else return null
}

export default AttachmentViewer
