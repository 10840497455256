import { Autocomplete } from "@material-ui/lab"
import { Toolbar, TextField } from "@material-ui/core"
import { useNavigate, useParams } from "react-router-dom"
import { UserRegions } from "../../../hooks/useRegions"
import styles from "./styles"
import classnames from "classnames"
import { t } from "../../../locales/utils"



interface RegionSelectProps {
  regions: UserRegions
}

const RegionSelect: React.FC<RegionSelectProps> = ({regions}) => {
  const classes = styles()
  const regionNames = Object.entries(regions).map(([region]) => region)
  const { region: paramRegion, organisationId } = useParams()
  const navigate = useNavigate()
  const currentRegion = regionNames.find(name => name.toLowerCase() === paramRegion)

  return (
    <Toolbar data-testid="RegionSelect" classes={{ root: classes.toolbar }}>
      <Autocomplete
        id="region-select"
        options={regionNames.sort((a,b) => a < b ? -1 : 1)}
        getOptionLabel={(option) => option}
        onChange={(e, value) => {
          if (value) navigate(`/${organisationId}/${value.toLowerCase()}`)
          else navigate(`/${organisationId}`)
        }}
        blurOnSelect
        noOptionsText={t("noOptionsText")}
        value={currentRegion || null}
        renderInput={(params) => <TextField {...params} placeholder={t("chooseRegion")} variant="outlined" />}
        className={classnames({
          [classes.autocomplete]: true,
          regionSelected: currentRegion 
        })}
      />
    </Toolbar>
  )
}

export default RegionSelect
