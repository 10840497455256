import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import useAuth0 from './hooks/useAuth0';
import { getOrganisation } from './utils';
import Main from './views/Main/Main';
import SpinnerFullscreen from './components/SpinnerFullscreen/SpinnerFullscreen';
import Error from './views/Error';

const App: React.FC = () => {
  const { login, logout } = useAuth0();
  const organisation = getOrganisation();

  return (
    <Routes>
      <Route path="" element={<Navigate to={`/${organisation}`} replace />} />
      <Route path="/callback" element={<SpinnerFullscreen />} />
      <Route path="/:organisationId/error" element={<Error />} />
      <Route path="/:organisationId/*" element={<Main login={login} logout={logout} />} />
      <Route path="/logout" element={<Navigate to={`/${organisation}`} replace />} />
    </Routes>
  );
};

export default App;
