import { Theme } from '@material-ui/core/styles';
import { Auth0Lock } from 'auth0-lock';
import i18next from 'i18next';
import { v4 as uuid } from 'uuid';
import { useState } from 'react';
import useGetProjectsQuery from '../axios/hooks/useGetProjectsQuery';
import auth0 from 'auth0-js';
import {
  ACCESS_TOKEN_LS_KEY,
  AUTH_STATE_LS_KEY,
  clearAuthData,
  EXPIRES_AT_LS_KEY,
  getOrganisation,
  USER_INFO_LS_KEY
} from '../utils';
import { useTheme } from '@material-ui/styles';
import StartUrlParameters from '../utils/startParameters';
import { ProjectsResponse } from '../axios/instances/types';

const AUTH0_CLIENT_ID = global.env.REACT_APP_AUTH0_CLIENT_ID;
const AUTH0_DOMAIN = global.env.REACT_APP_AUTH0_DOMAIN;
const AUTH0_SCOPE = 'openid profile email app_metadata user_id';
const AUTH0_RESPONSE_TYPE = 'token';

const LOGOUT_ROUTE = 'logout';

const parseLanguage = (languageCode = '') => {
  return (
    languageCode.indexOf('-') === -1 ? languageCode : languageCode.substring(0, 2)
  ).toLowerCase();
};

const useAuth0 = () => {
  const theme = useTheme<Theme>();
  const projectsQuery = useGetProjectsQuery();
  const [lock, setLock] = useState<Auth0LockStatic | null>(null);

  const login = () => {
    localStorage.removeItem(StartUrlParameters.error);
    localStorage.removeItem(StartUrlParameters.logout);

    lock?.show({
      auth: {
        params: {
          state: storeAuthState()
        }
      }
    });
  };

  const logout = () => {
    const url = new URL(`${window.location.origin}/${LOGOUT_ROUTE}`);
    url.searchParams.append('returnTo', window.location.href);

    lock?.logout({ returnTo: url.href });

    clearAuthData();
  };

  // eslint-disable-next-line
  const onLoginSucceeded = (authResult: any, lock: Auth0LockStatic) => {
    if (authResult.accessToken && lock) {
      lock.getUserInfo(authResult.accessToken, (error, profile) => {
        if (error) {
          onError(error);
        } else {
          const state = localStorage.getItem(AUTH_STATE_LS_KEY);
          const storageState = state ? JSON.parse(state) : null;
          const url = authResult.state && storageState?.url ? new URL(storageState.url) : null;

          if (!url) {
            onError('Failed to parse redirect url');
          } else {
            localStorage.setItem(USER_INFO_LS_KEY, JSON.stringify(profile));

            if (authResult.expiresIn) {
              const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
              localStorage.setItem(ACCESS_TOKEN_LS_KEY, authResult.accessToken);
              localStorage.setItem(EXPIRES_AT_LS_KEY, expiresAt);
            }

            window.location.replace(url);
          }
        }
      });
    }
  };

  const onError = (error: string | Error | auth0.Auth0Error) => {
    console.error(error);
    const auth0Error = error as auth0.Auth0Error;

    // Auth0Lock handles errors with error codes
    if (auth0Error.code) return;

    localStorage.setItem(StartUrlParameters.error, 'error.loginFailed');
    window.location.replace(`/${getOrganisation()}/login`);
  };

  const storeAuthState = () => {
    const state = uuid();
    localStorage.setItem(AUTH_STATE_LS_KEY, JSON.stringify({ state, url: window.location.href }));

    return state;
  };

  const getConnection = () => {
    const url = new URL(window.location.href);

    return url.searchParams.get('connection');
  };

  const createWebAuth = (connection: string) => {
    const webAuth = new auth0.WebAuth({
      domain: global.env.REACT_APP_AUTH0_DOMAIN,
      clientID: global.env.REACT_APP_AUTH0_CLIENT_ID
    });

    webAuth.authorize({
      connection,
      responseType: AUTH0_RESPONSE_TYPE,
      scope: AUTH0_SCOPE,
      redirectUri: `${window.location.origin}/callback`,
      state: storeAuthState()
    });
  };

  const createLock = () => {
    const { connections, organisationLogoDark, organisationLogoDarkThumb } =
      projectsQuery.data as ProjectsResponse;

    const auth0Lock = new Auth0Lock(AUTH0_CLIENT_ID, AUTH0_DOMAIN, {
      allowSignUp: false,
      allowedConnections: [global.env.REACT_APP_AUTH0_DATABASE, ...(connections || [])],
      configurationBaseUrl: 'https://cdn.eu.auth0.com',
      usernameStyle: 'email',
      defaultDatabaseConnection: global.env.REACT_APP_AUTH0_DATABASE,
      auth: {
        params: {
          scope: AUTH0_SCOPE
        },
        responseType: AUTH0_RESPONSE_TYPE,
        sso: false, // Enabling sso seems to break getting of app_metadata
        redirectUrl: `${window.location.origin}/callback`
      },
      language: parseLanguage(i18next.language),
      languageDictionary: {
        title: ''
      },
      container: 'login-container',
      theme: {
        logo: organisationLogoDark,
        primaryColor: theme.palette.primary.main,
        authButtons: connections.reduce((pre, connection) => {
          pre[connection] = {
            displayName: connection.split('-')[0],
            icon: organisationLogoDarkThumb
          };
          return pre;
        }, {} as Auth0LockThemeButtonOptions)
      }
    });
    setLock(auth0Lock);

    auth0Lock.on('authenticated', (authResult) => {
      onLoginSucceeded(authResult, auth0Lock);
    });
    auth0Lock.on('authorization_error', onError);
  };

  if (projectsQuery.data) {
    const connection = getConnection();

    if (connection) {
      createWebAuth(connection);
    } else if (!lock) {
      createLock();
    }
  }

  return {
    login,
    logout
  };
};

export default useAuth0;
