import {
  AppBar,
  Slide,
  Toolbar,
  useScrollTrigger,
  Typography,
  CssBaseline,
  Grid,
  Link,
} from "@material-ui/core"
import React from "react"
import { t } from "../../locales/utils"
import AttachmentViewer from "../AttachmentViewer/AttachmentViewer"
import { AttachmentFileType } from "../AttachmentViewer/types"
import useAttachmentViewer from "../AttachmentViewer/useAttachmentViewer"
import useStyles from "./styles"

interface Props {
  window?: () => Window;
  children?: React.ReactElement;
}

function HideOnScroll(props: Props) {
  const { children, window } = props
  const trigger = useScrollTrigger({ target: window ? window() : undefined })

  return (
    <Slide appear={false} direction="up" in={!trigger}>
      {children}
    </Slide>
  )
}

export default function Footer(props: Props) {
  const classes = useStyles()
  const copyright = String.fromCharCode(169)

  const { open, setOpen, attachment, setAttachment} = useAttachmentViewer()

  return (
    <>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar position="fixed" className={classes.main} data-testid="Footer">
          <Toolbar>
            <Grid
              container
              spacing={4}
              direction="row"
              justifyContent="center"
              className={classes.links}
            >
              <Grid item>
                <Link
                  className={classes.link}
                  href="https://gbuilder.blob.core.windows.net/legal/GBuilder%20Terms%20of%20Use.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()

                    setAttachment({
                      name: t("footer.termsOfService"),
                      link:  "https://gbuilder.blob.core.windows.net/legal/GBuilder%20Terms%20of%20Use.pdf",
                      type: AttachmentFileType.PDF
                    })
                    setOpen(true)
                  }}
                >
                  <Typography>
                    {t("footer.termsOfService")}
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link
                  className={classes.link}
                  href="https://gbuilder.blob.core.windows.net/legal/GBuilder%20Privacy%20Policy.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()

                    setAttachment({
                      name: t("footer.privacyPolicy"),
                      link:  "https://gbuilder.blob.core.windows.net/legal/GBuilder%20Privacy%20Policy.pdf",
                      type: AttachmentFileType.PDF
                    })
                    setOpen(true)
                  }}
                >
                  <Typography>
                    {t("footer.privacyPolicy")}
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link
                  className={classes.link}
                  href="https://gbuilder.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography>{copyright}GBuilder</Typography>
                </Link>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
      <AttachmentViewer open={open} attachment={attachment} handleClose={() => setOpen(false)} />
    </>
  )
}
