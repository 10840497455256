import { Alert } from '@material-ui/lab';
import React from 'react';
import useStartParams from '../hooks/useStartParams';
import useText from '../hooks/useText';
import { getOrganisation } from '../utils';
import StartUrlParameters from '../utils/startParameters';

const Error: React.FC = () => {
  const text = useText();
  const startParams = useStartParams();

  return (
    <Alert variant="outlined" severity="error" style={{ margin: '1rem' }}>
      {text(startParams[StartUrlParameters.error] || 'error.default', {
        organisation: getOrganisation()
      })}
    </Alert>
  );
};

export default Error;
