import i18n from "i18next"
import LngDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import resources from "../locales"

const parseLanguage = (language: string): string => {
  return language.replace("_", "-")
}

const params = new URLSearchParams(window.location.search)
const langParam = params.get("lan")
const lng = langParam !== null ? parseLanguage(langParam) : undefined

const instance = i18n
  .use(LngDetector)
  .use(initReactI18next)
  .init({
    lng,
    resources,
    debug: false,
    fallbackLng: "en",
    load: "languageOnly",
    ns: "main",
    defaultNS: "main",
    interpolation: {
      escapeValue: false
    },

    react: {
      wait: true,
      nsMode: "default",
      useSuspense: false
    }
  })

export default instance
