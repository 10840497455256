import React, { useState } from 'react';
import {
  Divider,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer
} from '@material-ui/core';
import { getUserInfo } from '../../../utils';
import useStyles from './styles';
import useText from '../../../hooks/useText';

const UserMenu: React.FC<{ logout: () => void }> = ({ logout }) => {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const user = getUserInfo();
  const classes = useStyles();
  const text = useText();

  return (
    <>
      <IconButton data-testid="UserMenu" onClick={() => setUserMenuOpen(true)}>
        <Icon>person</Icon>
      </IconButton>
      <SwipeableDrawer
        anchor="right"
        onClose={() => setUserMenuOpen(false)}
        open={userMenuOpen}
        onOpen={() => setUserMenuOpen(true)}
      >
        <div role="presentation" className={classes.menu}>
          <List>
            <ListItem>
              <ListItemText primary={user?.name} />
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem button onClick={logout}>
              <ListItemIcon className={classes.logoutItem}>
                <Icon>logout</Icon>
              </ListItemIcon>
              <ListItemText primary={text('logout')} />
            </ListItem>
          </List>
        </div>
      </SwipeableDrawer>
    </>
  );
};

export default UserMenu;
