import React  from "react"
import { IconButton, Menu, MenuItem } from "@material-ui/core"
import { languages } from "../../../i18n"
import { Language } from "@material-ui/icons"
import i18next from "i18next"
import useStyles from "./styles"

const LanguageSelect: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const classes = useStyles()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }
  const changeLanguage = async (code: string): Promise<void> => {
    await i18next.changeLanguage(code)
    setAnchorEl(null)
    window.location.reload()
  }
  return (
    <>
      <IconButton
        data-testid="LanguageSelect"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.root}
      >
        <Language />
      </IconButton>
      <Menu
        id="simple-menu"
        data-testid="LanguageSelect-menu"
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {languages.map((language) => (
          <MenuItem
            key={`selector-${language.code}`}
            onClick={() => changeLanguage(language.code)}
            disabled={i18next.language === language.code}
          >
            {language.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default LanguageSelect
