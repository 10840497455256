import i18next, { TOptions } from "i18next"

export const t = (s: string, options?: TOptions): string => {
  const translation = i18next.t(
    [/* `${params.company}.${s}`, */`${s}`],
    options
  )

  if (translation === undefined) console.warn("Missing translation:", s)

  return translation ?? s
}
