import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      maxWidth: theme.typography.pxToRem(480),
      minWidth: theme.typography.pxToRem(330),
      width: theme.typography.pxToRem(330),
      background: theme.palette.common.white,
      paddingTop: '1rem',
      paddingLeft: '1rem',
      paddingRight: '1rem',
      marginRight: '0.75rem',
      marginLeft: '0.75rem',
      marginBottom: '2.5rem',
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '0 0 2px rgb(0, 0, 0, .1), 0 1px 2px rgb(0, 0, 0, .2)',
      [theme.breakpoints.between('xs', 'md')]: {
        minWidth: theme.typography.pxToRem(330),
        width: '45%',
        flexGrow: 1
      },
      [theme.breakpoints.down('xs')]: {
        width: '90%',
        marginRight: '0',
        marginBottom: '1rem'
      },
      '&:first-child:last-child': {
        width: '60%'
      },
      '&:first-child:nth-last-child(2)': {
        width: 'calc(50% - 1.5rem)'
      },
      '&:last-child:nth-child(2)': {
        width: 'calc(50% - 1.5rem)'
      }
    },
    gridContainer: {
      flexGrow: 1,

      '& > div': {
        height: '100%'
      }
    },
    content: {
      marginTop: '1rem',
      maxWidth: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr',
      padding: '0 0.25rem'
    },
    title: {
      fontSize: theme.typography.h6.fontSize,
      // using any because of weird typedefs
      fontWeight: theme.typography.fontWeightBold as any, // eslint-disable-line
      color: theme.palette.common.black,
      paddingBottom: 0
    },
    subheader: {
      marginTop: '1rem',
      fontSize: theme.typography.body1.fontSize,
      color: theme.palette.common.black
    },
    actionArea: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '.5rem',
      margin: '1rem .25rem',
      alignItems: 'end'
    },
    pos: {
      marginBottom: 12
    },
    actionButtonText: {
      fontSize: theme.typography.caption.fontSize
    },
    buttonLink: {
      textDecoration: 'none',
      color: theme.palette.primary.light
    },
    leftover: {
      background: theme.palette.common.black,
      paddingLeft: '0.5rem',
      marginLeft: '-0.25rem',
      marginRight: '-0.25rem',
      flexGrow: 1,
      backgroundColor: theme.palette.common.white
    },
    sectionHeader: {
      borderBottom: theme.custom?.SectionCard?.header?.borderBottom,
      padding: theme.custom?.SectionCard?.header?.padding,
      paddingBottom: theme.custom?.SectionCard?.header?.paddingBottom,
      width: theme.custom?.SectionCard?.header?.width,
      margin: theme.custom?.SectionCard?.header?.margin
    },
    icon: {
      display: theme.custom?.SectionCard?.buttonIcon?.display
    }
  });
});
