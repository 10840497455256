import { createStyles, makeStyles } from '@material-ui/core/styles';

export default makeStyles(() =>
  createStyles({
    menu: {
      minWidth: '210px'
    },
    logoutItem: {
      minWidth: 'unset',
      paddingRight: '.7rem'
    }
  })
);
