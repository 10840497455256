import { Project } from '../types/Project';
import useRegions from './useRegions';

const useProjects = () => {
  const { regions, environments, loading } = useRegions();

  const projects = regions
    ? Object.entries(regions).reduce((projects, region) => {
        projects.push(...region[1]);
        return projects;
      }, [] as Project[])
    : [];

  return { projects, environments, loading };
};

export default useProjects;
