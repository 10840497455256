import { useState } from "react"
import { AttachmentType } from "./types"

interface AttachmentViewerInitialState {
  open?: boolean
  attachment?: AttachmentType
}

const useAttachmentViewer = (initialState?: AttachmentViewerInitialState) => {
  const [open, setOpen] = useState(!!initialState?.open)
  const [attachment, setAttachment] = useState<AttachmentType | undefined>(initialState?.attachment)

  return {open, setOpen, attachment, setAttachment}
}

export default useAttachmentViewer
