import React from 'react';
import { t } from '../../../locales/utils';
import { Project as IProject } from '../../../types/Project';
import { UserLevel } from '../../../types/UserInfo';
import { getHighest, isAuthenticated } from '../../../utils';
import {
  getCoreClientUrl,
  getCoreUrl,
  getCustomerJourneyUrl,
  isCoreClientUser,
  isCoreUser,
  isCustomerJourneyUser
} from '../../../utils/redirections';
import ImagePresenter from '../../ImagePresenter/ImagePresenter';
import SectionCard, { ActionButton } from '../SectionCard/SectionCard';
import useStyles from './styles';

const createButtonActions = (project: IProject): ActionButton[] => {
  if (!isAuthenticated()) return [];

  const level = getHighest(
    project.environmentLevel,
    project.organisationLevel,
    project.level,
    project.apartments?.[0]?.level
  );

  const coreClientAction = isCoreClientUser(level)
    ? {
        url: getCoreClientUrl(project),
        buttonText: t('openInCoreClient')
      }
    : null;

  const cjAction =
    level === UserLevel.SUPER_USER || isCustomerJourneyUser(level)
      ? {
          url: getCustomerJourneyUrl(project),
          buttonText: t('openInCj')
        }
      : null;

  const coreAction =
    level === UserLevel.SUPER_USER || (!cjAction && isCoreUser(level))
      ? {
          url: getCoreUrl(project),
          buttonText: t('openInCore')
        }
      : null;

  return [cjAction, coreAction, coreClientAction].filter((action) => !!action) as ActionButton[];
};

interface ProjectProps {
  project: IProject;
}

const Project: React.FC<ProjectProps> = ({ project }) => {
  const classes = useStyles();

  return (
    <SectionCard component={{ name: project.name }} actions={createButtonActions(project)}>
      <div data-testid="Project" className={classes.imageContainer}>
        <ImagePresenter
          className={classes.image}
          alt={`${project.name}-image`}
          src={project.imageUrl}
        />
      </div>
    </SectionCard>
  );
};

export default Project;
