import useStyles from './styles';
import defaultLogo from './defaultLogo.png';
import useGetProjectsQuery from '../../../axios/hooks/useGetProjectsQuery';

function CustomerLogo() {
  const classes = useStyles();
  const projectsQuery = useGetProjectsQuery();
  const logoUrl = projectsQuery.data?.organisationLogo || defaultLogo;

  return logoUrl && !projectsQuery.isLoading ? (
    <img data-testid="CustomerLogo" className={classes.image} alt="logo" src={logoUrl} />
  ) : null;
}

export default CustomerLogo;
