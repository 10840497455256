import { createTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core';
import React from 'react';
import useCustomFont from './hooks/useCustomFont';
import themes, { createOrganisationTheme } from './styles/themes/themes';

const ThemeWrapper: React.FC = ({ children }) => {
  const defaultThemeId = 'default';
  const { fontFamily, fontUrl } = useCustomFont();

  const getThemeId = () => {
    const paths = window.location.pathname.split('/');
    const themeId = paths.length >= 2 ? paths[1] : defaultThemeId;

    return themes[themeId] ? themeId : defaultThemeId;
  };

  const theme = createOrganisationTheme(getThemeId(), { typography: { fontFamily } });

  return (
    <ThemeProvider theme={responsiveFontSizes(createTheme(theme))}>
      {fontUrl && (
        <link data-testid="font-link-element" rel="stylesheet" type="text/css" href={fontUrl} />
      )}
      {children}
    </ThemeProvider>
  );
};

export default ThemeWrapper;
