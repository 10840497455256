export interface Attachable {
  title: string;
  source: string;
  caption?: string;
  mimetype?: string;
  height?: number;
  width?: number;
  scroll?: ScrollType;
  type?: AttachmentFileType;
  filename: string;
}

export enum ScrollType {
  PAPER = "paper",
  BODY = "body",
}

export enum AttachmentFileType {
  PDF = "pdf",
  IMAGE = "image",
  VIDEO = "video",
  OTHER = "other",
}

export interface ContextType {
  tenantId: string;
  domain: string;
  id: number;
  name: string;
  itemType: string;
}

export interface AttachmentType {
  description?: string;
  uploadDate?: string;
  link: string;
  projectId?: number;
  context?: ContextType;
  apartmentId?: null | number;
  id?: number;
  name: string;
  type?: AttachmentFileType;
}
