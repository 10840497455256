import { CSSProperties } from 'react';

interface ImagePresenterProps {
  src: string;
  alt: string;
  style?: CSSProperties;
  className?: string;
}

const ImagePresenter = ({ src, alt, style, className }: ImagePresenterProps) => {
  if (!src) src = './brokenImage.png';

  return (
    <img
      data-testid="ImagePresenter"
      className={className}
      alt={alt}
      src={src}
      style={style}
      onError={(e) => {
        if (e.currentTarget.src !== require('./brokenImage.png')) {
          e.currentTarget.src = require('./brokenImage.png');
        }
      }}
    />
  );
};

export default ImagePresenter;
