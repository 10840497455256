import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
  createStyles({
    loginImage: {
      width: '100%',
      height: '100%',
      objectPosition: 'center center',
      objectFit: 'cover',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  })
);
