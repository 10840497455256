import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

export default makeStyles((theme: Theme) => {
  return createStyles({
    image: {
      width: "100%",
      height: "auto",
      maxHeight: "100%"
    },
    imageContainer: {
      width: "100%",
      height: theme.typography.pxToRem(180)
    }
  })
})
