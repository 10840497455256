import { AxiosRequestConfig } from "axios"
import i18n from "i18next"
import { ConfigOptions } from "./types"
export const baseUrl = global.env.REACT_APP_API_URL

export const createConfig: (options: ConfigOptions) => AxiosRequestConfig = ({
  token,
  timeout,
  headers = { "accept-language": i18n.language || "en" },
  params,
}) => {
  return {
    baseURL: baseUrl,
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : undefined,
    },
    timeout,
    params,
  }
}
